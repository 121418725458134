<template>
    <div class="portal-service-wrapper">
        <b-overlay :show="loading">
            <div class="card text-center">
                <div class="card-body" v-if="verifyData.id">
                    <img style="width: 55px" src="../../../assets/images/verify.png" alt=""> <br>
                    <span class="text-success">{{$i18n.locale === 'bn' ? 'সফলভাবে যাচাই করা হয়েছে।':'Verify Successfully!!'}}</span>
                    <p>
                        {{$t('globalTrans.applicant_name')}}: {{ $i18n.locale === 'bn' ? verifyData.name_bn : verifyData.name_en }} <br>
                        {{ $t('allocation_management.company_name') }}: {{ $i18n.locale === 'bn' ? verifyData.comp_name_bn : verifyData.comp_name_en }} <br>
                        {{ $t('ditfConfig.stall_no') }}: {{ verifyData.stall_pavilion?.booked_stall_no ? verifyData.stall_pavilion?.booked_stall_no : verifyData.stall_pavilion[0]?.stall.stall_no}}
                    </p>
                </div>
                <div class="card-body" v-else>
                    <img style="width: 55px" src="../../../assets/images/unverified.png" alt="">
                    <p class="text-danger">
                        {{ $i18n.locale === 'bn' ? 'দুঃখিত! আমরা এই অনুরোধের জন্য কোনো স্টল বুকিংয়ের অনুরোধ খুঁজে পাইনি।':'Sorry! We could not find any stall booking requests for this inquiry.'}}
                    </p>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
    import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'

    export default {
        components: {
        },
        data () {
            return {
                service_name: '',
                verifyData: '',
                loading: false
            }
        },
        created () {
           if (this.$route.params.id) {
                this.loadData()
           }
        },
        computed: {
        },
        watch: {
        },
        methods: {
            async loadData () {
                this.loading = true
                const params = {
                    id: this.$route.params.id,
                    stall_id: this.$route.params.stall_id
                }
                const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, '/portal/ditf-stall-verify', params)
                this.loading = false
                if (result.success) {
                    this.verifyData = result.data
                } else {
                    this.verifyData = ''
                }
            }
        }
    }
</script>
<style>
    .portal-service-wrapper .service-tab .nav-tabs{
        background-color: var(--portal-brand);
    }
    .portal-service-wrapper .service-badge{
        color: var(--portal-secondary);
        background-color: var(--white);
    }
</style>
